import React, { useState } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { Heading, IconArrowHead, Specs } from '../../components';

import css from './ListingPage.module.css';

const SectionSpecs = props => {
  const [breakdownDirection, setBreakdownDirection] = useState('up');
  const { specs } = props;

  return (
    <div className={css.sectionSpecs}>
      <details open>
        <summary
          onClick={() => setBreakdownDirection(prevState => (prevState === 'down' ? 'up' : 'down'))}
        >
          <Heading as="h2" rootClassName={css.sectionHeadingWithExtraMargin}>
            <FormattedMessage id="ListingPage.specsTitle" />
          </Heading>
          <IconArrowHead direction={breakdownDirection} />
        </summary>

        <Specs specs={specs} />
      </details>
    </div>
  );
};

export default SectionSpecs;
