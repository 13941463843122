import React, { useState } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { Heading, IconArrowHead } from '../../components';
import { richText } from '../../util/richText';

import css from './ListingPage.module.css';

const SectionInfo = props => {
  const [breakdownDirection, setBreakdownDirection] = useState('up');
  const { info } = props;

  const content = info.split('\n').map((line, index) => (
    <React.Fragment key={index}>
      {line}
      <br />
    </React.Fragment>
  ));

  return info ? (
    <div className={css.sectionInfo}>
      <details open>
        <summary
          onClick={() => setBreakdownDirection(prevState => (prevState === 'down' ? 'up' : 'down'))}
        >
          <Heading as="h2" rootClassName={css.sectionHeadingWithExtraMargin}>
            <FormattedMessage id="ListingPage.infoTitle" />
          </Heading>

          <IconArrowHead direction={breakdownDirection} />
        </summary>

        <div className={css.sectionInfoContent}>{content}</div>
      </details>
    </div>
  ) : null;
};

export default SectionInfo;
