import React, { useState } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { Heading, IconArrowHead } from '../../components';

import css from './ListingPage.module.css';

const SectionAbout = props => {
  const [breakdownDirection, setBreakdownDirection] = useState('up');
  const { about } = props;

  return (
    <div className={css.sectionAbout}>
      <details open>
        <summary
          onClick={() => setBreakdownDirection(prevState => (prevState === 'down' ? 'up' : 'down'))}
        >
          <Heading as="h2" rootClassName={css.sectionHeadingWithExtraMargin}>
            <FormattedMessage id="ListingPage.aboutTitle" />
          </Heading>

          <IconArrowHead direction={breakdownDirection} />
        </summary>

        {/* Make */}
        {about.customMake || about.make ? (
          <li className={css.aboutTextContainer}>
            <b>Make</b>
            {about.makeNotInList ? <p>{about.customMake}</p> : <p>{about.make}</p>}
          </li>
        ) : null}

        {/* Model */}
        {about.model ? (
          <li className={css.aboutTextContainer}>
            <b>Model</b>
            <p>{about.model}</p>
          </li>
        ) : null}

        {/* Year */}
        {about.year ? (
          <li className={css.aboutTextContainer}>
            <b>Year</b>
            <p>{about.year}</p>
          </li>
        ) : null}

        {/* Categories */}
        {about.type?.length ? (
          <li className={css.aboutTextContainer}>
            <b>Category</b>
            <p>{about.type.join(' | ').replaceAll('-', ' ')}</p>
          </li>
        ) : null}

        {/* Condition */}
        {about.condition ? (
          <li className={css.aboutTextContainer}>
            <b>Condition</b>
            <p>{about.condition.replaceAll('-', ' ')}</p>
          </li>
        ) : null}

        {/* Colour */}
        {about.colour?.length ? (
          <li className={css.aboutTextContainer}>
            <b>Colour(s)</b>
            <p>{about.colour.join(' | ')}</p>
          </li>
        ) : null}

        {/* Frame size */}
        {about.frameSize ? (
          <li className={css.aboutTextContainer}>
            <b>Frame size</b>
            <p>
              {Number.isNaN(Number(about.frameSize)) ? about.frameSize : `${about.frameSize}cm`}
            </p>
          </li>
        ) : null}
      </details>
    </div>
  );
};

export default SectionAbout;
